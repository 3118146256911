<template>
  <transition name="slide" appear>
    <div
      class="flex items-center border p-4 pl-3 rounded-lg"
      :class="backgroundClasses"
    >
      <WebccIcon :name="icon" class="mr-4 size-6" :class="textClasses" />
      <span class="text-start">
        {{ message }}
      </span>
    </div>
  </transition>
</template>

<script lang="ts" setup>
interface Props {
  message: string
  type?: 'success' | 'error' | 'warning' | 'info'
  shadow?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  minWidth?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  shadow: 'sm',
  type: 'info',
  minWidth: false,
})

const icon = computed(() => {
  switch (props.type) {
    case 'success':
      return 'site/check'
    case 'error':
      return 'site/error-warning'
    case 'warning':
      return 'site/warning'
    case 'info':
      return 'reservations/info-new'
    default:
      return 'reservations/info-new'
  }
})

const backgroundClasses = computed(() => {
  let classes = ''
  switch (props.type) {
    case 'success':
      classes += ' border-suc'
      break
    case 'error':
      classes += ' border-err'
      break
    case 'warning':
      classes += ' border-wrn'
      break
    case 'info':
      classes += ' border-bgr-700'
      break
  }

  if (props.minWidth) classes += ' min-w-[300px]'

  return (classes += ` shadow-${props.shadow}`)
})

const textClasses = computed(() => {
  switch (props.type) {
    case 'success':
      return 'text-suc'
    case 'error':
      return 'text-err'
    case 'warning':
      return 'text-wrn'
    case 'info':
      return 'text-bgr-600'
    default:
      return 'text-bgr-600'
  }
})
</script>

<style scoped>
.slide-enter-active {
  animation: slide 300ms ease-out;
}
.slide-leave-active {
  animation: slide 300ms ease-out reverse;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-50%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
